import SecondAdLong from '@assets/images/websiteBanner/WebsiteBanner-970x100.jpg'
import SecondAdLongMWeb from '@assets/images/websiteBanner/WebsiteBanner-320x50.jpg'
import SecondAdRect from '@assets/images/websiteBanner/WebsiteBanner-300x300.jpg'
import SecondAdRectLeft from '@assets/images/websiteBanner/WebsiteBanner-360x250.jpg'
import SecondAdVertical from '@assets/images/websiteBanner/WebsiteBanner-160 x 600.jpg'

const UTM_CAMPAIGN = 'hawk'

const UTM_SOURCE = 'otn_web'

const BASE_URL = 'https://sg11fantasy.com'

export const AdPlacement = {
  secondAd_long: {
    imageURL: SecondAdLong,
    imageMWebURL: SecondAdLongMWeb
  },
  secondAd_Rect: {
    imageURL: SecondAdRect
  },
  secondAdRect_Left: {
    imageURL: SecondAdRectLeft
  },
  secondAd_Vertical: {
    imageURL: SecondAdVertical
  },
  secondAd_large: {
    imageURL: SecondAdLongMWeb
  }
}

export function createAdURL(utmMedium) {
  return `${BASE_URL}/?utm_campaign=${UTM_CAMPAIGN}&utm_source=${UTM_SOURCE}&utm_medium=${utmMedium}`
}
