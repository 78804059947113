export const googleAddData = {
  addTop: {
    slotId: '23202123844',
    addId: 'homepage_ATF_728*90',
    divId: 'div-gpt-ad-1726652608872-0',
    dimension: [728, 90]
  },
  addRight: {
    slotId: '23202123844',
    addId: 'homepage_RightBTF_300*600',
    divId: 'div-gpt-ad-1726652772504-0',
    dimension: [300, 600]
  },
  addLeft: {
    slotId: '23202123844',
    addId: 'homepage_LeftBTF_300*250',
    divId: 'div-gpt-ad-1726652694986-0',
    dimension: [300, 250]
  },
  mobSm: {
    slotId: '23202123844',
    addId: 'homepage_CentreATF_320*50',
    divId: 'div-gpt-ad-1726653462524-0',
    dimension: [320, 50]
  },
  mobLg: {
    slotId: '23202123844',
    addId: 'homepage_Mweb_BTF_300*300',
    divId: 'div-gpt-ad-1726652906460-0',
    dimension: [300, 300]
  }
}
