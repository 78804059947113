export const skyAddData = {
  addRight: {
    addId: 'IntertialATS_Desktop',
    divId: 'div-gpt-ad-1727788360769-0',
    dimension: [300, 300],
    slotId: '23202123844'
  },
  addLeft: {
    addId: 'SKY_LEFT_160*600',
    divId: 'div-gpt-ad-1727869548448-0',
    dimension: [160, 600],
    slotId: '23202123844'
  },
  addMweb: {
    addId: 'SKY_MWEB_200*200',
    divId: 'div-gpt-ad-1727871985452-0',
    dimension: [200, 200],
    slotId: '23202123844'
  },
  addBottom: {
    addId: 'sky_bottom_970*250',
    divId: 'div-gpt-ad-1727871903590-0',
    dimension: [970, 250],
    slotId: '23202123844'
  }
}
