import React from 'react'
import PropTypes from 'prop-types'
import CustomLink from '../customLink'
import dynamic from 'next/dynamic'
import useWindowSize from '@shared/hooks/windowSize'
import { AdPlacement, createAdURL } from '@shared/libs/advertisment-banner'
import { BLUR_DATA_URL_BASE64 } from '@shared-submodule/sm-constants'

const MyImage = dynamic(() => import('@shared/components/myImage'))

const StaticBannerSecond = ({ adType, isTop, link }) => {
  const [width] = useWindowSize()

  // const data = isTop ? adsConfig.top : width < 767 ? adsConfig.middleBarMob[index] : isLeft ? getRandomItem(adsConfig.leftSidebar) : isMiddle ? adsConfig.middleBarDesk[index] : getRandomItem(adsConfig.rightSidebar)
  const img = width > 767 ? AdPlacement?.[adType]?.imageURL : (AdPlacement?.[adType]?.imageMWebURL || AdPlacement?.[adType]?.imageURL)
  const url = createAdURL(link)
  return (
    <div className={`downloadApp staticBanner p-0 position-relative d-none overflow-hidden mb-2 ${adType === 'secondAd_long' && 'banHgtFrst mt-3'}`}>
      <CustomLink href={url}>
        <a target="_blank">
          <MyImage
            // loading={isTop ? 'eager' : 'lazy'}
            priority={isTop}
            placeholder="blur"
            src={img}
            alt="SBZ app"
            blurDataURL={BLUR_DATA_URL_BASE64}
            layout='responsive'
          />
        </a>
      </CustomLink>
    </div >
  )
}
StaticBannerSecond.propTypes = {
  adType: PropTypes.string,
  link: PropTypes.string,
  isTop: PropTypes.bool
  // isMiddle: PropTypes.bool,
  // isFirst: PropTypes.bool,
  // index: PropTypes.number
}

export default React.memo(StaticBannerSecond)
