// import topBanner from '@assets/images/homepageMiddleBanner.png'
// import sideBanner1 from '@assets/images/bannerSide_1.png'
// import sideBanner3 from '@assets/images/bannerSide_3.png'
// import bannerMiddleDesk2 from '@assets/images/bannerMiddleDesk_2.png'
// import bannerMiddleDesk3 from '@assets/images/bannerMiddleDesk_3.png'
// import bannerMiddleDesk4 from '@assets/images/bannerMiddleDesk_4.png'
// import bannerMiddleMob1 from '@assets/images/bannerMiddleMob_1.png'
// import bannerMiddleMob2 from '@assets/images/bannerMiddleMob_2.png'
// import bannerMiddleMob5 from '@assets/images/bannerMiddleMob_5.png'
// import bannerMiddleMob6 from '@assets/images/bannerMiddleMob_6.png'
// import sideBanner4 from '@assets/images/bannerSide_4.png'
import apolloTopBanner1 from '@assets/images/apolloTopBanner_1.jpeg'
import apolloTopBanner2 from '@assets/images/apolloTopBanner_2.jpeg'
import middleBanner1 from '@assets/images/apolloMiddleBanner_1.jpg'
import middleBanner2 from '@assets/images/apolloMiddleBanner_2.jpg'
import sideBanner1 from '@assets/images/apolloSideBanner_1.jpg'
import sideBanner2 from '@assets/images/apolloSideBanner_2.jpg'
import mobileBanner1 from '@assets/images/apolloMobBanner1.jpeg'
import mobileBanner2 from '@assets/images/apolloMobBanner2.jpeg'

// export const adsConfig = {
//   top: {
//     url: topBanner,
//     link: 'https://bit.ly/3Xzf0r3'
//   },
//   leftSidebar: [
//     {
//       url: sideBanner1,
//       link: 'https://bit.ly/3Kmynhy'
//     },
//     {
//       url: sideBanner3,
//       link: 'https://bit.ly/3Xzf0r3'
//     },
//     {
//       url: sideBanner4,
//       link: 'https://t.ly/6kXpL'
//     }
//   ],
//   rightSidebar: [
//     {
//       url: sideBanner1,
//       link: 'https://bit.ly/3Kmynhy'
//     },
//     {
//       url: sideBanner3,
//       link: 'https://bit.ly/3Xzf0r3'
//     },
//     {
//       url: sideBanner4,
//       link: 'https://t.ly/6kXpL'
//     }
//   ],
//   middleBarDesk: [
//     {
//       url: bannerMiddleDesk2,
//       link: 'https://bit.ly/3Kmynhy'
//     },
//     {
//       url: bannerMiddleDesk4,
//       link: 'https://bit.ly/3Xzf0r3'
//     },
//     {
//       url: bannerMiddleDesk3,
//       link: 'https://bit.ly/3Xzf0r3'
//     }
//   ],
//   middleBarMob: [
//     {
//       url: bannerMiddleMob5,
//       link: 'https://bit.ly/3Kmynhy'
//     },
//     {
//       url: bannerMiddleMob1,
//       link: 'https://bit.ly/3Xzf0r3'
//     },
//     {
//       url: sideBanner1,
//       link: 'https://bit.ly/44d4OXK'
//     },
//     {
//       url: bannerMiddleMob2,
//       link: 'https://bit.ly/3Xzf0r3'
//     },
//     {
//       url: bannerMiddleMob6,
//       link: 'https://bit.ly/3Kmynhy'
//     },
//     {
//       url: sideBanner4,
//       link: 'https://t.ly/6kXpL'
//     }
//   ]
// }

export const adsConfig = {
  top: {
    url: apolloTopBanner1,
    link: 'https://aplapollo.com/'
  },
  leftSidebar: [
    {
      url: sideBanner1,
      link: 'https://aplapollo.com/'
    }
  ],
  rightSidebar: [
    {
      url: sideBanner2,
      link: 'https://aplapollo.com/'
    }
  ],
  middleBarDesk: [
    {
      url: middleBanner1,
      link: 'https://aplapollo.com/'
    },
    {
      url: middleBanner2,
      link: 'https://aplapollo.com/'
    },
    {
      url: apolloTopBanner2,
      link: 'https://aplapollo.com/'
    }
  ],
  middleBarMob: [
    {
      url: mobileBanner1,
      link: 'https://aplapollo.com/'
    },
    {
      url: mobileBanner2,
      link: 'https://aplapollo.com/'
    }
  ]
}
